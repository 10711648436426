module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":650,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"k6","short_name":"k6","start_url":"/","background_color":"#fff","theme_color":"#7d64ff","display":"minimal-ui","icon":"./src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"b39a6f3e0dc925c8ec4f77e0a65490e9"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":[{"resolve":"/home/runner/work/k6-blog/k6-blog/node_modules/gatsby-remark-autolink-headers","id":"a1dea8bc-f180-54d9-9f56-3efe23676fa2","name":"gatsby-remark-autolink-headers","version":"5.25.0","modulePath":"/home/runner/work/k6-blog/k6-blog/node_modules/gatsby-remark-autolink-headers/index.js","pluginOptions":{"plugins":[],"removeAccents":true,"maintainCase":false,"icon":false,"elements":["h1","h2","h3"]},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onInitialClientRender","shouldUpdateScroll"],"ssrAPIs":["onRenderBody"]},{"resolve":"/home/runner/work/k6-blog/k6-blog/node_modules/gatsby-remark-copy-linked-files","id":"cf104666-f93f-5e9d-9c2e-1bd9003e1e14","name":"gatsby-remark-copy-linked-files","version":"5.25.0","modulePath":"/home/runner/work/k6-blog/k6-blog/node_modules/gatsby-remark-copy-linked-files/index.js","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]},{"resolve":"/home/runner/work/k6-blog/k6-blog/node_modules/gatsby-remark-images","id":"795d6e46-bfd3-5756-ae2b-441a94f45204","name":"gatsby-remark-images","version":"6.25.0","modulePath":"/home/runner/work/k6-blog/k6-blog/node_modules/gatsby-remark-images/index.js","pluginOptions":{"plugins":[],"maxWidth":800,"quality":90,"withWebp":true,"backgroundColor":"white","disableBgImageOnAlpha":true,"wrapperStyle":{"marginBottom":20},"showCaptions":["title"]},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]}],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/home/runner/work/k6-blog/k6-blog","commonmark":false,"JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"removeAccents":true,"maintainCase":false,"icon":false,"elements":["h1","h2","h3"]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":800,"quality":90,"withWebp":true,"backgroundColor":"white","disableBgImageOnAlpha":true,"wrapperStyle":{"marginBottom":20},"showCaptions":["title"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-twitter/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://b529379c441a4bc7b29df29613e7edbe@o175050.ingest.sentry.io/5509571","environment":"production"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-KBV9L4L","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
