import React from 'react';

export const WhatToLearn = ({ lessons = [], title = 'What you will learn' }) => (
  <blockquote>
    <h3 id="what-you-will-learn">
      📖
      <span style={{ marginLeft: '.5em' }}>{title}</span>
    </h3>
    <ul style={{ paddingLeft: '2em' }}>
      {lessons.map((lesson) => (
        <li>{lesson}</li>
      ))}
    </ul>
  </blockquote>
);
