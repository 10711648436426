/* eslint-disable no-nested-ternary */
import classNames from 'classnames';
import { Button } from 'components/shared/button';
import React from 'react';

import styles from './cta.module.scss';

export const CTA = ({
  title,
  description,
  buttonText,
  inPost,
  buttonURL,
  buttonRef,
  buttonTarget,
  openDrift,
  // eslint-disable-next-line no-unused-vars
  openDriftMessage,
}) => (
  <section className={classNames(styles.wrapper, { [styles.post]: inPost })}>
    <div className={'container'}>
      <div className={'row'}>
        <div className={`${inPost ? 'col-12' : 'col-xl-10 offset-xl-1'}`}>
          <div className={styles.inner}>
            <div className={styles.content}>
              <p className={styles.title}>{title}</p>
              <p className={styles.description}>{description}</p>
            </div>

            {openDrift ? (
              <Button
                className={styles.ctaButton}
                size={'lg'}
                theme={'gradient-primary'}
                onClick={() => {
                  if (typeof window.drift.api !== 'undefined') {
                    window.drift.api.sidebar.toggle();
                  } else {
                    // eslint-disable-next-line no-undef
                    navigate('/contact');
                  }
                }}
                cursor
              >
                {buttonText}
              </Button>
            ) : buttonRef ? (
              <Button
                className={styles.ctaButton}
                tag={'a'}
                href={buttonRef}
                target={buttonTarget}
                size={'lg'}
                theme={'gradient-primary'}
                cursor
              >
                {buttonText}
              </Button>
            ) : (
              <Button
                className={styles.ctaButton}
                tag={'link'}
                to={buttonURL}
                size={'lg'}
                theme={'gradient-primary'}
                cursor
              >
                {buttonText}
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  </section>
);
